<template>
  <div class="container">
    <RoleCard theme="student">
      <template #label>Приглашение от наставника НТО</template>
      <template #label_img>
        <img
          src="@/assets/images/reg_school.svg"
          alt="иконка" />
      </template>
      <template #body>
        <div class="row">
          <div class="col-md-8 col-sm-12 col-xs-12">
            <template v-if="!pending">
              <!-- 1. Пользователь не авторизован ни в ОНТИ ни В Таланте -->
              <div v-if="noUser && !talentId">
                <p class="mb-l">
                  Войдите в&nbsp;учетную запись участника, чтобы принять
                  приглашение.
                </p>
                <BaseButton
                  tag="a"
                  :href="loginLink"
                  >Войти</BaseButton
                >
              </div>

              <!-- 2. Пользователь авторизован в таланте но нет аккаунта в ОНТИ -->
              <div v-else-if="noUser && talentId">
                <p
                  v-if="mentor"
                  class="mb-s">
                  Наставник {{ mentor.first_name }}
                  {{ mentor.last_name }} приглашает вас принять участие
                  в&nbsp;НТО в&nbsp;качестве его ученика.
                </p>
                <p class="mb-l">
                  Зарегистрируйтесь на Олимпиаду, чтобы прянять это приглашение
                </p>
                <BaseButton
                  tag="router-link"
                  :to="{
                    name: 'registration',
                    query: {
                      ...$route.query,
                      next: $route.path,
                    },
                  }"
                  >Регистрация</BaseButton
                >
              </div>

              <!-- 3. Пользователь -- наставник -->
              <div v-else-if="isMentor">
                <p class="mb-l">
                  Вы не можете принять это приглашение, так как являетесь
                  Наставником.
                </p>
                <BaseButton
                  tag="router-link"
                  :to="{ name: 'mentor-index' }"
                  >В кабинет наставника</BaseButton
                >
              </div>

              <!-- 4. С пользователем все ок. И ментор получен  -->
              <div v-else-if="mentor">
                <p class="mb-l">
                  Наставник {{ mentor.first_name }}
                  {{ mentor.last_name }} приглашает вас принять участие
                  в&nbsp;НТО<span v-if="selectedProfile">
                    в профиле &laquo;{{ selectedProfile.title }}&raquo;</span
                  >
                  в&nbsp;качестве его ученика.<br />
                  Если вы&nbsp;примете приглашение, наставник сможет следить
                  за&nbsp;вашим прогрессом на&nbsp;олимпиаде.
                </p>

                <div v-if="!inviteProfileError">
                  <ValidationObserver
                    v-if="isHighEducation && educationRequired"
                    ref="form"
                    tag="form"
                    class="row mb-m">
                    <div class="col-md-4 col-sm-6 col-xs-12 form-row">
                      <label class="form-label">Факультет *</label>
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required">
                        <BaseInput
                          v-model="form.faculty"
                          :errors="errors"
                          theme="reg" />
                      </ValidationProvider>
                    </div>
                    <div class="col-md-4 col-sm-6 col-xs-12 form-row">
                      <label class="form-label">Специальность *</label>
                      <ValidationProvider
                        v-slot="{ errors }"
                        rules="required">
                        <BaseInput
                          v-model="form.speciality"
                          :errors="errors"
                          theme="reg" />
                      </ValidationProvider>
                    </div>
                  </ValidationObserver>

                  <BaseButton
                    class="mb-s"
                    @click="acceptInvite"
                    >Принять приглашение</BaseButton
                  >
                  &emsp;<BaseButton
                    tag="router-link"
                    :to="{ name: 'user-index' }"
                    >Отказаться</BaseButton
                  >
                </div>
                <BaseButton
                  v-else
                  tag="router-link"
                  theme="reg"
                  :to="{ name: 'user-index' }"
                  >Перейти в кабинет</BaseButton
                >
              </div>

              <!-- 5. Что-то пошло не так... не получен ментор, неправильная ссылка ...etc  -->
              <div v-else>
                <p>Неправильная ссылка.</p>
              </div>

              <div
                v-if="error"
                class="mt-m">
                <p class="form-error">{{ error }}</p>
              </div>
            </template>
            <BaseSpinner
              v-else
              brand />
          </div>
        </div>
      </template>
    </RoleCard>
  </div>
</template>

<script>
/**
 * @page InviteParticipant
 * Страница инвайта для участника.
 * (Ментор просит участника стать его подопечным)
 */
import { mapGetters } from "vuex";
import RoleCard from "@/components/registration/RoleCard.vue";
import {
  FINAL_STAGE,
  HIGH_EDUCATION_ALIAS,
  PARTICIPANT_ROLE,
} from "@/constants";
import { talentRequest, request } from "@/services/api";
import { getJuniorFinalEvent } from "@/utils/participant";
export default {
  name: "InviteParticipant",
  components: { RoleCard },
  metaInfo() {
    return {
      title: "Приглашение участника НТО от наставника",
    };
  },
  data() {
    return {
      noUser: false,
      mentor: null,
      pending: true,
      error: "",
      inviteProfileError: false,
      invitePending: false,
      organization: null,
      /**
       * Тип обрзовательного учреждения в которое приглашают юзера
       */
      educationalCategory: null,
      educationRequired: false,
      form: {
        faculty: "",
        speciality: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "user/user",
      isMentor: "user/isMentor",
      talentId: "user/talentId",
      profiles: "profile/profiles",
      selectedList: "participant/selectedList",
      isJunior: "participant/isJunior",
      isStudent: "participant/isStudent",
      currentEducation: "participant/currentEducation",
    }),
    requiredFinalEvent() {
      return (
        this.isJunior &&
        this.user?.participant?.grade > 4 &&
        this.$route.query.profile_id
      );
    },
    events() {
      return this.$store.state.profile.events;
    },
    educations() {
      return this.$store.state.user.educations;
    },
    loginLink() {
      const { profile_id } = this.$route.query;
      let next = `/registration?next=${this.$route.fullPath}`;
      if (profile_id) {
        next += `&profile_id=${profile_id}`;
      }
      let url = `${
        this.$store.state.clientApiPrefix
      }/auth/go?next=${encodeURIComponent(next)}`;

      return url;
    },
    /**
     * Если в ссылке есть инвайт в профиль
     */
    selectedProfile() {
      const { profile_id } = this.$route.query;
      if (!profile_id) return;
      // Если такой профиль уже выбран у юзера,
      // то ничего делать не нужно
      const selected = this.selectedList.find((n) => {
        return n.id === Number(profile_id);
      });
      if (selected) return;
      // Вернем профиль если он есть в списке допустимых на регистрацию
      // Этого юзера
      const profile = this.profiles.find((n) => {
        return n.id === Number(profile_id);
      });

      if (!profile) return;

      // Для джунов нужно найти мероприятие финала
      if (this.requiredFinalEvent) {
        const events = profile.steps
          ?.filter((step) => step.stage === FINAL_STAGE && step.talent_event_id)
          .map((step) => {
            return this.events[step.talent_event_id];
          });
        const finalEvent = getJuniorFinalEvent(events, this.currentEducation);

        return {
          ...profile,
          finalEvent,
        };
      }

      return profile;
    },

    isHighEducation() {
      return this.educationalCategory === HIGH_EDUCATION_ALIAS;
    },
  },
  created() {
    this.init();
  },
  methods: {
    async getMentor() {
      const { data } = await talentRequest({
        method: "GET",
        url: `/api/users/${this.$route.params.mentor}/`,
      });
      this.mentor = data;
    },
    async getOrg() {
      const { data } = await talentRequest({
        method: "GET",
        url: `/api/organizations/${this.$route.params.org}/`,
      });
      this.organization = data;
    },
    async init() {
      this.pending = true;

      // Получаем информацию об организации
      // необходимо, чтобы понять, отправлять или нет объект education
      // в инвайт запросе
      try {
        await this.getOrg();
      } catch (error) {
        this.error = `Не удалось получить данные об организации ${this.$route.params.org}. Возможно вы перешли по неправильной ссылке. Запросите ссылку повторно или обратитесь в службу поддержки.`;
        this.pending = false;
        return;
      }

      // Получаем информацию о менторе
      try {
        await this.getMentor();
      } catch (error) {
        if (error.status === 404) {
          this.error =
            "Наставник с таким id не найден. Проверьте правильность ссылки, или попросите вашего наставника отправить вам ссылку повторно.";
        } else {
          this.error = error.message;
        }
        this.pending = false;
        return;
      }

      // Получаем информацию о текущем пользователе
      try {
        await this.$store.dispatch("user/getMe");
        if (this.user.role === PARTICIPANT_ROLE) {
          await this.$store.dispatch("profile/getProfiles");
          await this.$store.dispatch("participant/getInfo", true);
          /**
           * Если есть талант пользователь, то нужно запросить
           * список образований юзера, чтобы не слать объект education
           * в инвайте от настаника, если у пользователя уже есть такое образование
           */
          await this.$store.dispatch("user/getEducation", true);
          if (this.requiredFinalEvent) {
            // Если это джуниор, то запросим мероприятия финала
            await this.$store
              .dispatch("profile/getAllFinalEvents")
              .catch((error) => {
                console.log("error", error);
              });
          }
        }
      } catch (error) {
        this.noUser = true;
        this.pending = false;
        // Пользователю предлагают авторизоваться по ивайту от ментора
        window.dataLayer?.push({
          event: "ntoRegformEventOauth",
          eventCategory: "nto_auth_with_talant",
          eventAction: "oauth_suggest",
          inviteType: "invite_from_mentor",
        });
        return;
      }

      try {
        const { data } = await talentRequest({
          method: "GET",
          url: `/api/users/${this.user.talent_id}/organizations/${this.$route.params.org}/member/invite/`,
        });

        this.educationRequired = !!data.education_required;
        this.educationalCategory = data.education_category;
      } catch (error) {
        //
      }

      this.pending = false;
    },
    /**
     * Обновляем образование у участника, в НТО если оно поменялось
     * в ходе инвайта в организацию.
     */
    async patchParticipant() {
      const current = this.$store.state.participant.talent_education_id;
      const org = +this.$route.params.org;
      try {
        await this.$store.dispatch("user/getEducation", true);
        const newEduction = this.educations.find((n) => n.organization === org);
        if (newEduction && newEduction.id !== current) {
          await this.$store.dispatch("participant/patchParticipant", {
            talent_education_id: newEduction.id,
          });
          await this.$store.dispatch("user/updateUserInfo", {
            current_education: newEduction.id,
          });
        }
      } catch (error) {
        console.log("error", error);
      }
    },
    async acceptInvite() {
      const { selectedProfile } = this;
      const { code, mentor, org } = this.$route.params;
      if (this.invitePending) return;

      if (this.$refs.form) {
        const isValid = await this.$refs.form.validate();
        if (!isValid) return;
      }

      this.invitePending = true;
      const payload = {
        code,
        mentor: +mentor,
        organization: +org,
      };

      /**
       * Если организация образовательная, то при инвайте
       * нужно передать объект с образованием
       */
      if (this.educationalCategory) {
        if (this.isHighEducation && !this.isStudent) {
          this.error =
            "Данная образовательная организация является вузом. Вы не можете принять это приглашение, так как участвуете в школьном треке олимпиады";
          this.invitePending = false;
          return;
        }

        if (this.educationRequired) {
          const grade = this.$store.state.participant.grade || 1;
          payload.education = {
            extra: {},
            grade,
            start: new Date().getFullYear() - grade,
          };

          if (this.isHighEducation) {
            payload.education.extra = {
              faculty: this.form.faculty,
              speciality: this.form.speciality,
            };
          }
        }
      }

      try {
        await request({
          method: "POST",
          url: `/mentor/invite`,
          data: payload,
        });

        // Если инвайт происходил в образовательную организацию,
        // то необходимо выставить текущее образование юзеру,
        // если оно еще не выставлено
        if (payload.education) {
          await this.patchParticipant();
        }

        // Если есть инвайт в профиль,
        // то посылаем запрос на выбор профиля
        if (selectedProfile) {
          try {
            const pPayload = { profile_id: selectedProfile.id };
            if (this.requiredFinalEvent) {
              if (!selectedProfile.finalEvent) {
                throw {
                  message: "Финал данной сферы не проводится в вашем регионе",
                };
              } else {
                pPayload.final_event = selectedProfile.finalEvent?.id;
              }
            }
            await this.$store.dispatch("participant/selectProfile", pPayload);
          } catch (error) {
            // Если у юзера не получилось заинвайтится в профиль
            // надо нарисовать кнопку - перейти в кабинет
            this.inviteProfileError = true;
            this.showErrorModal({
              title: "Ошибка",
              message: error.message,
              status: error.status,
              content: "Не удалось выбрать предлагаемый наставником профиль",
            });
            this.invitePending = false;
            return;
          }
        }

        try {
          await this.$store.dispatch("participant/getInfo", true);
        } catch (error) {
          console.log(error);
        }
        this.invitePending = false;
        this.$router.push({
          name: "user-index",
        });
      } catch (error) {
        this.error = error.message;
        this.invitePending = false;
      }
    },
  },
};
</script>

<style></style>
